import React from 'react'
import { PUBLICATIONS } from '../../components/constant'
import Layout from '../../components/layout'
import PageStructure from '../../components/pagestructure'
import SEO from '../../components/seo'
const Blogs = () => {
  return (
    <div>
      <Layout>
        <SEO title="Blogs" />
        <PageStructure title="Blogs" desc="TESTIG" mode={PUBLICATIONS} />
      </Layout>
    </div>
  )
}

export default Blogs
